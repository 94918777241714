import { useDecision } from 'lib/optimizely';
import { ABTestKeys, Variation } from 'lib/optimizely/flagConfigs';

import { LoanSelector as LoanSelectorWithBestOffer } from './LoanSelectorWithBestOffer';
import { LoanSelector as LoanSelectorWithoutBestOffer } from './LoanSelectorWithoutBestOffer';

interface LoanSelectorProps {
  indexOnBestOffer: string | undefined;
}

export const LoanSelector = (props: LoanSelectorProps) => {
  const { indexOnBestOffer = '1' } = props;

  const [decision] = useDecision(ABTestKeys.CAT_3290_best_offer_box_removal_AB);
  const variation = decision?.variationKey || Variation.CONTROL;
  const showBestOfferBox = variation !== Variation.VARIATION_B; // key could be "off"

  if (showBestOfferBox) {
    return <LoanSelectorWithBestOffer indexOnBestOffer={indexOnBestOffer} />;
  }

  return <LoanSelectorWithoutBestOffer indexOnBestOffer={indexOnBestOffer} />;
};
