import type { IBankLegalAddress, IBestOffer } from 'contexts/BestOfferContext';

import { formatNumberCurrency, formatNumber } from '../helpers/formatNumber';
import { LoanSelectionProps } from 'contexts/LoanSelectionContext';

export const prefixWithCommaIfNotEmpty = (text: string) => {
  return text.length ? `, ${text}` : text;
};

export const formatBankLegalAddress = (bankLegalAddress: IBankLegalAddress) => {
  const { name, secondLine, street, zipCode, city } = bankLegalAddress;

  const bankNameWithAddress = `${name}${prefixWithCommaIfNotEmpty(
    secondLine,
  )}, ${street}, ${zipCode} ${city}`;
  return bankNameWithAddress;
};

// Changed WS-2699
// Changed WS-2987
export const calculateMonthlyPayment = (
  amount: number,
  interest: number,
  term: number,
) => {
  const base = (interest / 100 + 1) ** (1 / 12);

  if (interest !== 0) {
    return amount * (base ** term / (base ** term - 1)) * (base - 1);
  }

  return amount / term;
};

// Changed WS-2699
// Changed WS-2987
const calculateTotal = (
  amount: number,
  interest: number,
  term: number,
  fee = 0,
) => {
  const monthlyPayment = calculateMonthlyPayment(amount, interest, term);

  if (monthlyPayment !== 0) {
    return monthlyPayment * term + fee;
  }

  return amount + fee;
};

// Change CAT-2488
export const getTwoThirdsLegalText = (
  bestOffer: IBestOffer | null,
  loanSelection: LoanSelectionProps,
) => {
  if (!bestOffer) return '';

  const {
    bankLegalAddress,
    condition: {
      fee,
      effectiveInterestRateTwoThird,
      nominalInterestRateTwoThird,
    },
  } = bestOffer;

  const { amount, term } = loanSelection;

  // Special case for minus interest rates, which cannot be calculated with the
  // formula, but are really a 0% interest rate with a bonus from us.
  const termAsNumber = typeof term === 'string' ? parseInt(term, 10) : term; // there is an odd type mismatch sometimes here
  const isMinusInterestRate = amount === 1000 && termAsNumber === 24;
  if (isMinusInterestRate) {
    return 'Alle Kunden erhalten: bei 1.000 € Nettodarlehensbetrag, 24 Monate Laufzeit, -0,40% effektiver Jahreszins, -0,40% p.a. gebundener Sollzins, 23 mtl. Raten zu je 41,67 und eine Schlussrate zu 41,59 €, 995,50 € Gesamtbetrag,Vereinigte Volksbank Raiffeisenbank eG, Darmstädter Str. 62, 64354 Reinheim. Der Zinssatz von -0,40% ergibt sich durch eine einmalige Ausgleichszahlung.';
  }

  const monthlyRate = calculateMonthlyPayment(
    amount,
    effectiveInterestRateTwoThird,
    term,
  );
  const totalTwoThird = calculateTotal(
    amount,
    effectiveInterestRateTwoThird,
    term,
    fee,
  );

  const formattedBankLegalAddress = formatBankLegalAddress(bankLegalAddress);
  const formattedAmount = formatNumberCurrency(amount);
  const formattedEffectiveInterestRateTwoThird = formatNumber(
    effectiveInterestRateTwoThird,
  );
  const formattedNominalInterestRateTwoThird = formatNumber(
    nominalInterestRateTwoThird,
  );
  const formattedFee = fee
    ? `Bearbeitungsgebühr ${formatNumberCurrency(fee)}, `
    : '';
  const formattedMonthlyRate = formatNumberCurrency(monthlyRate);
  const formattedTotalTwoThird = formatNumberCurrency(totalTwoThird);

  const legalText = `2/3 aller Kunden erhalten: Nettodarlehensbetrag ${formattedAmount}, ${term} Monate Laufzeit, effektiver Jahreszins ${formattedEffectiveInterestRateTwoThird}%, fester Sollzins p.a. ${formattedNominalInterestRateTwoThird}%, ${formattedFee}${term} mtl. Raten zu je ${formattedMonthlyRate}, Gesamtbetrag ${formattedTotalTwoThird}${prefixWithCommaIfNotEmpty(
    formattedBankLegalAddress,
  )}.`;

  return legalText;
};
